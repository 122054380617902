var site = site || {};
site.Slider = (function () {
	// Functions


	/**
	 * Swiper 
	 * https://swiperjs.com/swiper-api#parameters
	*/
	var initSwiper = function () {
		//console.log('initSwiper');
		var swipers = document.querySelectorAll('.js-swiper');
		//console.log('swipers:', swipers);

		if(swipers.length > 0) {
			var swiperCount = 0;
			swipers.forEach(swiperInstance => {
				//console.log('swiperInstance:', swiperInstance);
				swiperInstance.setAttribute('data-swiper-id', swiperCount)
				const swiper = new Swiper(swiperInstance, {
					// Optional parameters
					// loop: true,
				  
					// If we need pagination
					pagination: {
						el: '.swiper-pagination',
					},
				  
					// // Navigation arrows
					// navigation: {
					// 	nextEl: '.swiper-button-next',
					// 	prevEl: '.swiper-button-prev',
					// },

				});
				swiperCount++;;
			});
		}
	}



	// Return
	return {
		initSwiper: initSwiper
	};
}());