var site = site || {};
site.BingMaps = function () {
	//console.log('refresh22.bing-maps.js loaded');

	//VARIABLES
	var _log = true,
		//general/config
		baseCategory = 'Sports Destinations',
		mapItems = null,
		map = null,
		isMobile = false, //mobile/desktop only, 760px brkpt
		breakPoints = {
			'main': 1024//760
		},
		wasMobile = null,
		baseZoom = 7,
		//tabletZoom = 6.5,//only integers work
		mobileZoom = 6,
		useZoom = null,
		useCenterLocation = null,
		radiusSearch = 30,//was 25
		//layers
		centeringLayer = null,
		countyLayer = null,
		localReportLayer = null,
		clusterLayer = null,
		isClusteringLoaded = false,
		colorPinSingle = '#004d71',
		colorPinCluster = 'rgba(0,77,113, 0.5)',//'#004d71',
		//location
		desktopCenter = null,
		tabletLandscapeCenter = null,
		tabletPortraitCenter = null,
		mobileCenter = null,
		centerLocation = null,
		mouseStartX = null,
		mouseStartY = null,
		mapX = null,
		mapY = null,
		point_wi_tl = null,
		point_wi_br = null,
		wiBounds = null,
		openId = "",
		reportInfoBoxArray = Array(),
		reportInfoBoxDetailsArray = Array(),
		to_map;


	//TEMPLATES
	var mapInfoboxTemplate = $('#mapInfoboxTemplate').html(),
		mapInfoboxDetailsTemplate = $('#mapInfoboxDetailsTemplate').html();
	

	//MAP OPTIONS
	var mapOptions = {
		//credentials: "At8Jkh0rmaGv5-mCIc6Q_2mia5ADadap_rZvH_waQYEREGWL1mBCQxegKjJ88U2x",//DEPRECATED
		zoom: baseZoom,
		center: centerLocation,
		enableSearchLogo: false,
		showScalebar: false,
		showDashboard: false,
		useInertia: false,
		disableKeyboardInput: true,
		disablePanning: false, //mobile.matches, // - remove all panning for MOBILE ONLY except when using the compass buttons
		liteMode: true
	};

	/**
	 * (RE)initializeMap Microsoft map functionality AFTER bing maps library is loaded, will be triggered via callback > map.js
	*/
	var initializeMap = function (config) {
		if (_log) console.log('BingMap.Map.initializeMap()', config);

		desktopCenter = new Microsoft.Maps.Location(45.205852, -90.03011);//YY, XX
		centerLocation = desktopCenter; // start with desktop
		centeringLayer = new Microsoft.Maps.Layer();
		wiBounds = Microsoft.Maps.LocationRect.fromEdges(53.882830, -104.858254, 37.131071, -75.932511); 
		// localReportLayer = new Microsoft.Maps.EntityCollection();
		// localReportLayer.LayerName = "LocalReports";

		trackResize(); //get initial window dims, set presets

		mapOptions = {
			mapTypeId: Microsoft.Maps.MapTypeId.canvasLight, //aerial,//grayscale//canvasLight//road//mercator
			minZoom: 5,
			maxBounds: wiBounds,
			zoom: useZoom,//baseZoom,
			center: useCenterLocation,//centerLocation,
			enableSearchLogo: false,
			showScalebar: false,
			showDashboard: false,
			useInertia: false,
			disableKeyboardInput: true,
			disablePanning: false, //mobile.matches, // - remove all panning for MOBILE ONLY except when using the compass buttons
			liteMode: true
		};
		if (_log) console.log('mapOptions:', mapOptions);

		map = new Microsoft.Maps.Map('#mapDestinations', mapOptions);
		map.entities.clear();

		//Load the Clustering module.
		Microsoft.Maps.loadModule("Microsoft.Maps.Clustering", function () {
			if (_log) console.log('Microsoft.Maps.Clustering');
			isClusteringLoaded = true;
			placeItemsOnMap();
		});

		Centering.init();

		// placeItemsOnMap();

		//Map Controls
		$('#zoomIn').on('click', function () {
			zoomMap('+');
		});
		$('#zoomOut').on('click', function () {
			zoomMap('-');
		});
		$('#resetMap').on('click', function () {
			softResetMap();
		});

	}





	var placeItemsOnMap = function (){
		mapItems = $('#mapDestinations').data('items');
		console.log('placeItemsOnMap.mapItems:', mapItems);

		clusterLayer = new Microsoft.Maps.ClusterLayer(createCustomPushpins(mapItems), {
			clusteredPinCallback: createCustomClusterPushpins,
			//callback: createPushpinList,
			visible: true,
			setZIndex: 99999
		});
		if (_log) console.log('clusterLayer', clusterLayer);

		Microsoft.Maps.Events.addHandler(clusterLayer, 'click', function(e) {
			var pinType = (e.target instanceof Microsoft.Maps.ClusterPushpin) ? 'Cluster': 'Pushpin';
			//if (_log) console.log('------------- Clicked ', pinType, ': ', e.target);
			//alert('clusterLayer click4');
		});


		map.layers.insert(clusterLayer);
		zoomToMarkers();

		$('.js-spinner').remove();
	}


	/**
	 * Create custom pushpins, inject extra datapoints
	 * @param {*} data 
	*/
	var createCustomPushpins = function (data) {
		console.log('createCustomPushpins.data:', data);

		var pins = [];
		for (var i = 0; i < data.length; i++) {

			var pinWidth = 22,
				pinHeight = Math.floor((pinWidth * 40) / 30);
			var svg = ['<svg title="test" width="' + pinWidth + '" height="' + pinHeight + '" class="svg-inline--fa fa-map-marker-alt fa-w-12 text-primary fa-lg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><path fill="' + colorPinSingle + '" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path></svg>'];

			var pushpinOptions = {
				color: colorPinSingle,
				icon: svg.join(''),
				anchor: new Microsoft.Maps.Point(pinWidth * .5, pinHeight),
			};

			var pinLocation = new Microsoft.Maps.Location(data[i].Latitude, data[i].Longitude);
			var pushpin = new Microsoft.Maps.Pushpin(pinLocation, pushpinOptions);
			
			//inject extra datapoints into pushpin
			pushpin.metaData = data[i];			
			pins.push(pushpin);

			//Add handler for the pushpin click event.
			Microsoft.Maps.Events.addHandler(pins[i], 'click', pushpinClicked);
			//if (_log) console.log('addHandler.pushpinClicked.i', i);
		}

		return pins;
	}
	
	
	/**
	 * Create custom pushpin clusters
	 * @param {*} cluster 
	 */
	var createCustomClusterPushpins = function (cluster) {
		var minRadius = 12;
		var outlineWidth = 7;
		var fillColor = colorPinCluster;

		//Get the number of pushpins in the cluster
		var clusterSize = cluster.containedPushpins.length;

		//Calculate the radius of the cluster based on the number of pushpins in the cluster, using a logarithmic scale.
		var radius = Math.log(clusterSize) / Math.log(10) * 12 + minRadius;

		//Create an SVG string of two circles, one on top of the other, with the specified radius and color.
		var svg = ['<svg xmlns="http://www.w3.org/2000/svg" width="', (radius * 2), '" height="', (radius * 2), '">',
		    '<circle cx="', radius, '" cy="', radius, '" r="', radius, '" fill="', fillColor, '"/>',
		    '<circle cx="', radius, '" cy="', radius, '" r="', radius - outlineWidth, '" fill="', fillColor, '"/>',
			'</svg>'];

		
		//Create a title for the cluster.
		cluster.setOptions({
			icon: svg.join(''),
			anchor: new Microsoft.Maps.Point(radius, radius),
			textOffset: new Microsoft.Maps.Point(0, radius - 6)
		});

		//Add handler for the cluster click event.
		Microsoft.Maps.Events.addHandler(cluster, 'click', clusterClicked); //pushpinClicked
		//if (_log) console.log('addHandler.clusterClicked.cluster', cluster);
	}



	/**
	 * Cluster clicked action
	 * @param {*} e 
	*/
	var clusterClicked = function (e) {
		if (_log) console.log('clusterClicked:', e);
		if (e.target.containedPushpins) {
			var locs = [];
			for (var i = 0, len = e.target.containedPushpins.length; i < len; i++) {
				//Get the location of each pushpin.
				locs.push(e.target.containedPushpins[i].getLocation());
			}

			//Create a bounding box for the pushpins.
			var bounds = Microsoft.Maps.LocationRect.fromLocations(locs);

			//Zoom into the bounding box of the cluster. 
			//Add a padding to compensate for the pixel area of the pushpins.
			map.setView({
				bounds: bounds,
				padding: 100
			});
			tw.Analytics.trackEvent(baseCategory, 'Map Cluster Click', '');
		}
	}


	/**
	 * Pushpin clicked action
	 * @param {*} e 
	*/
	var pushpinClicked = function (e) {
		if (_log) console.log('pushpinClicked()e:', e);
		infoBoxExpand(e.target.metaData.Id);
		// //Show an infobox when a pushpin is clicked.
		tw.Analytics.trackEvent(baseCategory, 'Map Location Click', e.target.metaData.Name);
	}










	/**
	 * Infobox Expand
	 * @param {*} id 
	*/
	var infoBoxExpand = function (id) {
		if (_log) console.log('infoBoxExpand()', id);
		deleteInfoboxDetails();
		
		const findMapItem = mapItems.find( ({ Id }) => Id == id );
		if (_log) console.log('findMapItem:', findMapItem);

		if(findMapItem) {

			openId = id;

			var compiledTemplate = Template7.compile(mapInfoboxDetailsTemplate);

			var jsonContent = {
				...findMapItem
			};
			var html = compiledTemplate(jsonContent);

			//infobox emebeded in map didn't work as well as one above it
			var infoboxWidth = 300,
				infoboxHeight = 310,
				infoboxOptions = {
					width: infoboxWidth,
					height: infoboxHeight,
					zIndex: 1,
					id: findMapItem.Name,
					offset: new Microsoft.Maps.Point((infoboxWidth * -.5), infoboxHeight) //xx, yy
				};
			var pinLocation = new Microsoft.Maps.Location(findMapItem.Latitude, findMapItem.Longitude);
			var detailsInfobox = new Microsoft.Maps.Infobox(pinLocation, infoboxOptions);
			detailsInfobox.setHtmlContent(html);
			detailsInfobox.setMap(map);
			reportInfoBoxDetailsArray.push(detailsInfobox);

			Microsoft.Maps.Events.addHandler(detailsInfobox, 'click', function () {
				console.log('detailsInfobox.click:', detailsInfobox);
				tw.Analytics.trackEvent(baseCategory, 'Map Click', findMapItem.Name);
				deleteInfoboxDetails();
			});

			//wait for image to load then check height and adjust
			setTimeout(() => {
				//console.log('adjust options');
				//detailsInfobox.setOptions({height: 180});//doesn't work
				var detailsHeight = $('.js-infobox-details').outerHeight() + 25;//plus some extra
				detailsInfobox.setOptions({offset: new Microsoft.Maps.Point((infoboxWidth * -.5), detailsHeight)});
				$('.js-infobox-details').removeClass('hidden');
			}, 100);

		}

	}
	





	/**
	 * Zoom to markers already on the map
	*/
	var zoomToMarkers = function () {
		if (_log) console.log('zoomToMarkers.mapItems', mapItems);
		var locs = [];
		mapItems.forEach(item => {
			//console.log('item:', item);
			locs.push(new Microsoft.Maps.Location(item.Latitude, item.Longitude));
		});
		var bounds = Microsoft.Maps.LocationRect.fromLocations(locs);
		map.setView({bounds:bounds, padding: 10});
	}





	/**
	 * Soft reset map.
	 * for use when map is centered/zoomed on local reports, zoom out to initial state and clear infoxboxes and cetering ring.
	*/
	var softResetMap = function () {
		if (_log) console.log('softResetMap()');

		// map.setView({
		// 	zoom: useZoom,
		// 	center: useCenterLocation
		// });
		zoomToMarkers();

	}


	/**
	 * Zoom Map
	 * @param {*} direction 
	 * @param {*} location 
	*/
	var zoomMap = function (direction, location) {
		if (_log) console.log('zoomMap():', direction, location);

		if (direction === '+') {
			var newZoom = getZoomLevel() + 1;
			map.setView({
				zoom: newZoom,
				center: location
			});
		} else if (direction === '-') {
			var newZoom = getZoomLevel() - 1;
			map.setView({
				zoom: newZoom,
				center: location
			});
		} else if (typeof direction === 'number') {
			map.setView({
				zoom: direction,
				center: location
			});
		}
		if (_log) console.log('zoomLevel: ', getZoomLevel());
	};

	/**
	 * Get Zoom Level of map
	*/
	var getZoomLevel = function () {
		return map.getZoom();
	};





	var deleteInfoboxDetails = function () {
		if (_log) console.log('deleteInfoboxDetails()');
		//$('.js-infobox-details').remove();

		//for deleting map embedded ones
		for (var i = 0; i < reportInfoBoxDetailsArray.length; i++) {
			//infobox.dispose();
			reportInfoBoxDetailsArray[i].setMap(null);
		}
		
	}






	/**
	 * Centering Layer
	 */
	var Centering = function () {
		//if (_log) console.log('Centering()');
		var _isCentered = false;
		var _latitude = 0;
		var _longitude = 0;

		var _qLatitude = 0;
		var _qLongitude = 0;

		var Reset = function () {
			//if (_log) console.log('Centering.Init/Reset()');
			_isCentered = false;
			_latitude = 0;
			_longitude = 0;
			_qLatitude = 0;
			_qLongitude = 0;
		};

		var initialize = function () {
			Reset();
		};

		var SetCenter = function (lat, long) {
			_latitude = lat;
			_longitude = long;
			_isCentered = true;
		};

		var SetQueryCenter = function (lat, long) {
			_qLatitude = lat;
			_qLongitude = long;
			_isCentered = true;
		};

		var IsCentered = function () {
			return _isCentered;
		};

		var CenterPoint = function () {
			return "" + _latitude + "," + _longitude + "";
		};

		var QueryCenterPoint = function () {
			return "" + _qLatitude + "," + _qLongitude + "";
		};

		return {
			init: initialize,
			SetCenter: SetCenter,
			SetQueryCenter: SetQueryCenter,
			Reset: Reset,
			IsCentered: IsCentered,
			CenterPoint: CenterPoint,
			QueryCenterPoint: QueryCenterPoint
		};
	}();



	//NOTE: MAP WON'T LOAD WITHOUT WIDTH/HEIGHT SET ON DIV

	var multiPinMap = function () {
		//console.log('refresh22.bing-maps.multiPinMap()');

		//var self = this;
		var map = null;
		var pinLocationsArray = null;
		var loadMap = function (locationsArray, domElement, zoomLevel) {
			// console.log('multiPinMap.loadMap()');
			// console.log('multiPinMap.loadMap()', locationsArray, domElement.id, zoomLevel);


			pinLocationsArray = locationsArray;
			var latitude = 44.832017349893526,
				longitude = -89.84687250000002;
			var center = new Microsoft.Maps.Location(latitude, longitude);
			map = new Microsoft.Maps.Map('#' + domElement.id, {
				mapTypeId: Microsoft.Maps.MapTypeId.canvasLight, //aerial,//canvasDark//grayscale//canvasLight
				enableSearchLogo: false,
				//showMapTypeSelector: false,
				showDashboard: false,
				zoom: 5,
				center: center
			});

			var x = 0;
			pinLocationsArray.forEach(location => {
				console.log('pinLocationsArray.location:', location);
				location.PinText = x + 1;
				AddLocation(location);
				x++;
			});

			if (zoomLevel != 'undefined' && x == 1) {
				console.log('map.setView:', locationsArray);
				var center = new Microsoft.Maps.Location(locationsArray[0].Latitude, locationsArray[0].Longitude);
				map.setView({
					center: center,
					zoom: zoomLevel
				});

			} else {
				console.log('use bestMapView()');
				bestMapView($(domElement).children(".MicrosoftMap").width(), $(domElement).children(".MicrosoftMap").height(), 75);
			}

			console.log('map:', map);

		}


		var isRoadTrip = false;
		var AddLocation = function (item) {
			console.log('multiPinMap.AddLocation()', item);
			var offset = new Microsoft.Maps.Point(0, 5);
			var pinOptions = {
				visible: true,
				textOffset: offset,
				draggable: false,
				iconStyle: 8
			};

			if (site.BingMaps.multiPinMap.IsRoadTrip) {
				pinOptions = pinImageForRoadTrip(pinOptions);
			} else {
				pinOptions = pinImageWithOutText(pinOptions);
			}

			var pin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(item.Latitude, item.Longitude), pinOptions);
			pin.Address = item.Address;

			map.entities.push(pin);
		};

		return {
			loadMap: loadMap,
			IsRoadTrip: isRoadTrip
		};
	}();

	var pinImageWithOutText = function (pinOptions) {
		console.log('pinImageWithOutText()', pinOptions);
		if (pinOptions == null || pinOptions == undefined)
			pinOptions = {};

		// pinOptions.icon = '/images/public/map_images/pushpin_2.png';
		// pinOptions.width = 16;
		// pinOptions.height = 22;

		var pinWidth = 22,
			pinHeight = Math.floor((pinWidth * 40) / 30);
		var svg = ['<svg title="test" width="' + pinWidth + '" height="' + pinHeight + '" class="svg-inline--fa fa-map-marker-alt fa-w-12 text-primary fa-lg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><path fill="' + colorPinSingle + '" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path></svg>'];
		pinOptions.icon = svg.join('');
		pinOptions.anchor = new Microsoft.Maps.Point(pinWidth * .5, pinHeight);

		return pinOptions;
	};


	var jkTest = function () {
		console.log('refresh22.bing-maps.jkTest()');
	}




	/**
	 * Recalculate zoom upon init/resize
	 */
	var recalculateZoom = function () {
		if (_log) console.group('recalculateZoom()');
		if (isMobile) {// || isTablet
			useZoom = mobileZoom;
			useCenterLocation = mobileCenter;
		} else {
			useZoom = baseZoom;
			useCenterLocation = desktopCenter;
		}

		
		if (_log) console.log('isMobile', isMobile);
		if (_log) console.log('useZoom', useZoom);
		if (_log) console.log('useCenterLocation', useCenterLocation);
		
		if (map != null) {
			map.setView({
				zoom: useZoom,
				center: useCenterLocation
			});
		}
		if (_log) console.groupEnd();
		
	};



	/**
	 * track resize event, update map if needed
	*/
	var trackResize = function () {
		if (_log) console.log('trackResize()');
		BingMap.Utilities.viewportDimensions();

		if (window.innerWidth < breakPoints.main) {
			isMobile = true;
		} else {
			isMobile = false;
		}

		if (wasMobile != isMobile) {
			if (_log) console.log('isMobile changed!!! update map. isMobile:', isMobile);
			wasMobile = isMobile;
			recalculateZoom();
		}
		// if (_log) console.log('wasMobile:', wasMobile);
		// if (_log) console.log('isMobile:', isMobile);
	}
	

	return {
		initializeMap: initializeMap,
		multiPinMap: multiPinMap,
		jkTest: jkTest,
	}
}();