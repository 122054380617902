var wdot = wdot || {};
wdot.Utils = function () {
    var setCookie = function (c_name, value, exdays, exhours) {
        var exdate = new Date();
        if (exdays != undefined && exdays != null) {
            exdate.setDate(exdate.getDate() + exdays);
        }
        if (exhours != undefined && exhours != null) {
            exdate.setHours(exdate.getHours() + exhours);
        }
        var c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString());
        document.cookie = c_name + "=" + c_value;
    },
    deleteCookie = function (c_name) {
        var date = new Date();
        date.setDate(date.getDate() - 1);
        setCookie(c_name, '', date);
    },
    getCookieValue = function (c_name) {
        var i, x, y, ARRcookies = document.cookie.split(";");
        for (i = 0; i < ARRcookies.length; i++) {
            x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
            y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
            x = x.replace(/^\s+|\s+$/g, "");
            if (x == c_name) {
                return unescape(y);
            }
        }
        return '';
    },
    htmlDecode = function (text) {
        return $("<div/>").html(text).text();
    },
    htmlEncode = function (text) {
        return $("<div/>").text(text).html();
    },
    resetPlaceHolders = function () {
        $('[placeholder]').each(function () {
            var input = $(this);
            if (input.val() == input.attr('placeholder')) {
                input.val('');
            }
        });
    },
    serializeObject = function (selector) {
        var o = {};
        var a = selector.serializeArray();
        $.each(a, function () {
            if (o[this.name] !== undefined) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
            } else {
                o[this.name] = this.value || '';
            }
        });
        return o;
    },
    getValueFromQueryStringByKey = function (key, queryString) {
        var value = '';
        if (window.location.search != '' && queryString == '') {
            queryString = window.location.search;
        }
        if (queryString != '') {
            $.each(queryString.split('&'), function (item) {
                var splitItem = this.split('=');
                if (splitItem[0] == key) {
                    value = splitItem[1];
                }
            });
        }
        return value;
    };
    return {
        SetCookie: setCookie,
        DeleteCookie: deleteCookie,
        HtmlDecode: htmlDecode,
        HtmlEncode: htmlEncode,
        ResetPlaceHolders: resetPlaceHolders,
        SerializeObject: serializeObject,
        GetCookieValue: getCookieValue,
        GetValueFromQueryStringByKey: getValueFromQueryStringByKey
    };
} ();