var wdot = wdot || {};
wdot.CityRegionsHelper = function() {
    return {

        //ORIG FROM JS/SPORTSWISCRIPTS.JS
        regionMapClick: function (region) {
            //console.log('wdot.CityRegionsHelper.regionMapClick: ' + region);
            $('.citiesRegionsMap .map').attr("src", '/Images/public/map_images/menu_' + region.replace(/-/g, '') + '.png');
            $('.map-list__link').removeClass('active');
            var dataName = region.replace(/-/g, '');
            $('.map-list__link.' + dataName).addClass('active');

            //HOMEPAGE HAS BOTH DEST/FACI IN TABS, UPDATING BOTH TO KEEP SEARCH ACTIVE IN BOTH
            $('#Destinations-Region').val($('.map-list__link.' + dataName).attr('data-value'));
            $('#Facilities-Region').val($('.map-list__link.' + dataName).attr('data-value'));
            $('#Region').val($('.map-list__link.' + dataName).attr('data-value'));
            //console.log($('#Destinations-Region').val() + ' && ' + $('#Facilities-Region').val());

        },

        setDropdownImage: function (name) {
            //console.log('wdot.CityRegionsHelper.setDropdownImage: ' + name);
            if (name == null || name == undefined || name.length == 0) {
                if (Modernizr.touch) {
                    name = "noselect-tablet";
                } else {
                    name = "noselect";
                }
            }
            var picExtension = '.jpg';
            if (name == 'noselect' || name == 'noselect-tablet') {
                picExtension = '.png';
            }

            $(".citiesRegionsMap .map").attr("src", "/Images/public/map_images/menu_" + name.replace("-tablet", "") + ".png");
            $(".regionBlocks li").removeClass("active");
            $(".regionBlocks li." + name).addClass("active");
            return false;
        },

        preloadRegionMapImages: function () {
            //console.log('wdot.cityregionshelper.preloadRegionMapImages');
            new Image().src = "/Images/public/map_images/menu_central.png";
            new Image().src = "/Images/public/map_images/menu_eastcentral.png";
            new Image().src = "/Images/public/map_images/menu_northeast.png";
            new Image().src = "/Images/public/map_images/menu_northwest.png";
            new Image().src = "/Images/public/map_images/menu_southcentral.png";
            new Image().src = "/Images/public/map_images/menu_southeast.png";
            new Image().src = "/Images/public/map_images/menu_southwest.png";

        },

        getUrlVars: function () {
            var vars = [], hash;
            var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
            for(var i = 0; i < hashes.length; i++)
            {
                hash = hashes[i].split('=');
                vars.push(hash[0]);
                vars[hash[0]] = hash[1];
            }
            return vars;
        },

        initLoadRegionMap: function () {
            var onloadRegion = wdot.CityRegionsHelper.getUrlVars()['Region'],
                name = 'noselect'
            ;
            //console.log('initLoadRegionMap: ' + onloadRegion);
            if (onloadRegion != undefined) {
                $('.js-map-list__link').each(function (index, ele) {
                    if ($(this).data('value') == onloadRegion) {
                        $(this).addClass('active');
                        name = $(this).data('name').replace("-", "")
                    }
                });
                $('.citiesRegionsMap .map').attr("src", '/Images/public/map_images/menu_' + name.replace("-", "") + '.png');
                $('#Destinations-Region').val(onloadRegion);
                $('#Facilities-Region').val(onloadRegion);
            }
        },

        initRegionMapButtons: function () {
            //console.log('LOAD wdot.CityRegionsHelper.initRegionMapButtons. found: ' + $('.js-map-list__link').length);
            wdot.CityRegionsHelper.initLoadRegionMap();

            //THINK WE CAN DROP THE TOUCH STUFF.
            //if (Modernizr.touch) {
            //    $("#citiesRegionsDropdownRegionSubmit").css("display", "block");
            //    $(".citiesRegions .regionBlocks li a").click(function (e) {
            //        wdot.CityRegionsHelper.setDropdownImage($(this).data("region-name"));
            //        e.stopPropagation();
            //        return false;
            //    });
            //    $(".citiesRegionsMap map area").click(function (e) {
            //        wdot.CityRegionsHelper.setDropdownImage($(this).data("region-name"));
            //        e.stopPropagation();
            //        return false;
            //    });
            //} else {

                $('.js-map-list__link').hover(
                    function () {
                        var name = $(this).attr('data-name');
                        //console.log('hover: ' + name);
                        $('.citiesRegionsMap .map').attr("src", '/Images/public/map_images/menu_' + name.replace("-", "") + '.png');
                    }, function () {
                        var activeItem = $(".map-list__item a.active");
                        var imgSrc = activeItem.attr('data-name');
                        if (imgSrc == undefined || imgSrc == '') imgSrc = 'noselect';
                        $('.citiesRegionsMap .map').attr("src", '/Images/public/map_images/menu_' + imgSrc.replace("-", "") + '.png');
                    }
                );

                $(".js-map-list__link").on('click', function () {
                    var that = $(this),
                        value = String(that.data('value'))
                        name = String(that.data('name')),//hyphen
                        nameclean = name.replace("-", "")
                        ;
                    //console.log('.js-map-list__link.click: ' + nameclean);

                    $('#Destinations-Region').val(value);
                    $('#Facilities-Region').val(value);
                    $('.js-map-list__link').removeClass('active');
                    $('.' + nameclean).addClass('active');
                    return false;
                });


                $(".citiesRegionsMap").on('click', function () {
                    //console.log('citiesRegionsMap.click');//CLEARS OUT MAP SELECTION
                    wdot.CityRegionsHelper.setDropdownImage();
                });

                $(".citiesRegionsMap map").on('click', function (event) {
                    //console.log('citiesRegionsMap map click');//DOES NOTHING, JUST BLOCK CLICKS FROOM BELOW
                    event.stopPropagation();
                });

                $(".citiesRegionsMap map area").on('click', function (event) {
                    //console.log('citiesRegionsMap map area region-name::: ' + $(this).data("region-name"));
                    wdot.CityRegionsHelper.setDropdownImage($(this).data("region-name"));
                    event.stopPropagation();
                });

            //}

        }
    };
}();