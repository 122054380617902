var wdot = wdot || {};
wdot.Ajax = function () {
    var error = function (xhr, ajaxOptions, thrownError) {
        if (xhr.status == 403 || xhr.status == 401) {
            if (thrownError == 'LoggedOut') {
                window.location = '/Admin/Account/LogOn';
            }
            else if (thrownError == 'Unauthorized') {
                window.location = '/Admin/Account/Unauthorized?UnauthorizedMessage=Unauthorized Ajax Call';
            }
        }
        else if (xhr.status == 0) {

        }
        else {
            alert("Error occured! " + xhr.status + " " + xhr.statusText);
        }
    },
        beforeSend = function () {
            $(".ajaxLoader").show();
            $(".ajaxLoader").removeClass("hidden");
        },
        complete = function () {
            $(".ajaxLoader").hide();
            $(".ajaxLoader").addClass("hidden");
        },
        post = function (url, data, successFn, dataType, contentType) {
            return $.ajax({
                type: 'Post',
                url: url,
                dataType: dataType,
                contentType: contentType ? contentType : "application/x-www-form-urlencoded",
                data: data,
                success: successFn
            });
        },
        get = function (url, data, successFn, dataType) {
            return $.ajax({
                type: 'Get',
                url: url,
                dataType: dataType,
                data: data,
                success: successFn
            });
        },
        addAjaxLoader = function (selector, useBig) {
            if (useBig) {
                return selector.append("<div class='ui-widget-overlay' style='width:" + $('body').width() + "px; height: " + $('body').height() + "px; z-index: 1001;'></div><div class='ajaxLoader' style='position:absolute; left:50%; top:50%; margin-left:-28px; margin-top:-28px; display:block;'><div class='ajaxImage' style='z-index:1002;background-image:url(/images/ajax-loader-big.gif); margin: auto; width:55px; height:55px; padding:0px'></div></div>");
            }

            return selector.append("<div class='ajaxLoader' style='width: 100%; height: 16px; padding-top: 5px; padding-bottom:5px;'><div class='ajaxImage' style='background-image:url(/images/ajax-loader.gif); margin:auto; width:16px; height:16px; padding:0px'></div></div>");
        },
        removeAjaxLoader = function (selector, useBig) {
            if (useBig) {
                selector.find('.ui-widget-overlay').remove();
            }
            selector.find('.ajaxLoader').remove();
        },
        ajaxQueue = [],
        clearQueue = function () {
            $(ajaxQueue).each(function () {
                this.abort();
            });
            ajaxQueue = [];
        },
        clearQueueAndPushNewCall = function (call) {
            clearQueue();
            addNewCall(call);
        },
        addNewCall = function (call) {
            ajaxQueue.push(call);
        };
    return {
        AddAjaxLoader: addAjaxLoader,
        RemoveAjaxLoader: removeAjaxLoader,
        Error: error,
        BeforeSend: beforeSend,
        Complete: complete,
        Post: post,
        Get: get,
        ClearQueue: clearQueue,
        ClearQueueAndPushNewCall: clearQueueAndPushNewCall,
        AddNewCall: addNewCall
    };
} ();