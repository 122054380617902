var tw = tw || {};
tw.Analytics = function () {

	var _log = false;

	/**
	 * Set pageview, used for tracking virtual page changes like in a SPA.
	 * @param {any} pagePath
	 * https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications
	 */
	var setPageview = function (pagePath) {
		if (_log) console.group('tw.Analytics.setPageview()');
		if (_log) console.log('pagePath: ', pagePath);
		if (_log) console.groupEnd();
		//ga('set', 'page', '/new-page.html');
		ga('set', 'page', pagePath);
	};

	var trackPageview = function(page) {
		if (_log) console.group('tw.Analytics.trackPageview()');
		if (_log) console.log('page: ', page);
		if (_log) console.groupEnd();
		ga('send', 'pageview', page);
	};

	var trackEvent = function(category, action, label) {
		if (_log) console.group('tw.Analytics.trackEvent()');
		if (_log) console.log('category: ', category);
		if (_log) console.log('action: ', action);
		if (_log) console.log('label: ', label);
		if (_log) console.groupEnd();
		ga('send', 'event', category, action, label);
	};

	return {
		trackEvent: trackEvent,
		trackPageview: trackPageview,
		setPageview: setPageview
	}

}();