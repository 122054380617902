var BingMap = BingMap || {};
BingMap.Utilities = function () {
	//console.log('bingmap-utilities.js');

	var _log = false,
		windowDimensions = { 'width': 0, 'height': 0 },
		dimensions = { 'width': 0, 'height': 0 };

	var viewportDimensions = function () {
		windowDimensions.width = window.innerWidth;
		windowDimensions.height = window.innerHeight;
		if (_log) console.log('windowDimensions:', windowDimensions);
	}

	var getElementDimensions = function ($ele) {
		dimensions.width = $ele.width();
		dimensions.height = $ele.height();
		if (_log) console.log('dimensions: ', dimensions);
		return dimensions;
	}

	var upperCaseWord = function (word) {
		//if (_log) console.log('upperCaseWord: ', word);
		var wordUpper = word.replace(/^\w/, function (chr) {
			return chr.toUpperCase();
		});
		return wordUpper;
	}

	var spaceToHyphenateString = function (str) {
		var newstr = str.replace(/\s+/g, '-');//.toLowerCase();
		return newstr;
		//console.log(newstr);
	}



	/**
	 * Get URL parameters
	 * @param {*} prop 
	 */
	var getUrlParams = function (prop) {
		if (_log) console.group('getUrlParams()');
		if (_log) console.log('prop', prop);
		
		var params = {},
			search = null,
			definitions = null;

		search = decodeURIComponent(window.location.href.slice(window.location.href.indexOf('?') + 1));
		definitions = search.split('&');

		if (definitions != null) {
			definitions.forEach(function (val, key) {
				var parts = val.split('=', 2);
				params[parts[0]] = parts[1];
			});
		}

		if (_log) console.log('search:', search);
		if (_log) console.log('definitions:', definitions);
		if (_log) console.log('params:', params);
		if (_log) console.groupEnd();

		return (prop && prop in params) ? params[prop] : params;
	}



	/**
	 * Draw Circle Line From Location
	 * This one uses kilometers, so convert all distances to kilometers first
	 * Another way to do a circle, from this SO post: http://stackoverflow.com/a/9332234/639628
	 * 
	 * @param {*} latitude 
	 * @param {*} longitude 
	 * @param {*} radius 
	 * @param {*} strokeColor 
	 * @param {*} strokeThickness 
	 */
	var drawCircleLineFromLocation = function (latitude, longitude, radius, strokeColor, strokeThickness) {
		//if (_log) console.log('DrawCircleLineFromLocation()', latitude, longitude);
		var R = 6378.1; // earth's mean radius in km

		var lat = (latitude * Math.PI) / 180;     
		var lon = (longitude * Math.PI) / 180;
		var d = parseFloat(radius) / R;
		var circlePoints = [];

		for (x = 0; x <= 360; x += 5) {
			var p2 = new Microsoft.Maps.Location(0, 0);
			var brng = x * Math.PI / 180;
			p2.latitude = Math.asin(Math.sin(lat) * Math.cos(d) + Math.cos(lat) * Math.sin(d) * Math.cos(brng));

			p2.longitude = ((lon + Math.atan2(Math.sin(brng) * Math.sin(d) * Math.cos(lat), 
						Math.cos(d) - Math.sin(lat) * Math.sin(p2.latitude))) * 180) / Math.PI;
			p2.latitude = (p2.latitude * 180) / Math.PI;
			circlePoints.push(p2);
		}

		//polyline options: https://msdn.microsoft.com/en-us/library/mt712655.aspx
		var polygon = new Microsoft.Maps.Polyline(circlePoints, {
			strokeColor: strokeColor,
			strokeThickness: parseInt(strokeThickness),
			strokeDashArray: "2 4 2 4"//"Dash, Gap, Dash, Gap"
		});
		return polygon;
	};



	/**
	 * Draw Circle From Location
	 * @param {*} latitude 
	 * @param {*} longitude 
	 * @param {*} radius 
	 * @param {*} fillColor 
	 * @param {*} strokeColor 
	 * @param {*} strokeThickness 
	 */
	var drawCircleFromLocation = function (latitude, longitude, radius, fillColor, strokeColor, strokeThickness) {
		//if (_log) console.log('DrawCircleFromLocation()', latitude, longitude);
		var R = 6378.1; // earth's mean radius in km

		var lat = (latitude * Math.PI) / 180;     
		var lon = (longitude * Math.PI) / 180;
		var d = parseFloat(radius) / R;
		var circlePoints = [];

		for (x = 0; x <= 360; x += 5) {
			var p2 = new Microsoft.Maps.Location(0, 0);
			var brng = x * Math.PI / 180;
			p2.latitude = Math.asin(Math.sin(lat) * Math.cos(d) + Math.cos(lat) * Math.sin(d) * Math.cos(brng));

			p2.longitude = ((lon + Math.atan2(Math.sin(brng) * Math.sin(d) * Math.cos(lat), 
						Math.cos(d) - Math.sin(lat) * Math.sin(p2.latitude))) * 180) / Math.PI;
			p2.latitude = (p2.latitude * 180) / Math.PI;
			circlePoints.push(p2);
		}

		var polygon = new Microsoft.Maps.Polygon(circlePoints, {
			fillColor: fillColor,
			strokeColor: strokeColor,
			strokeThickness: parseInt(strokeThickness),
			strokeDashArray: "3 4"//"Dash, Gap, Dash, Gap"
		});
		return polygon;
	};





	/**
	 * Convert Miles to Kilo
	 * Utility method for converting miles to kilometers
	 * @param {*} miles 
	 */
	var convertMilesToKilometers = function (miles) {
		var kilometers = miles * 1.60934;
		return kilometers;  
	};










	
	/**
	 * Method that will get a layer or entity by name assuming it has a LayerName property on it.
	 * @param {*} layerName 
	 */
	var getLayerByName = function (layerName, map) {
		//if (_log) console.log('BingMaps.Utilities.GetLayerByName()', layerName, map);
		return getEntityByProperty(map.layers, 'LayerName', layerName);
	};

	/**
	 * This Method is similar to the above but more flexible and allows you to get a layer based on the value of any public property.
	 * @param {*} collection 
	 * @param {*} propertyName 
	 * @param {*} propertyValue 
	 */
	var getEntityByProperty = function (collection, propertyName, propertyValue) {
		//if (_log) console.log('BingMaps.Utilities.getEntityByProperty()', collection, propertyName, propertyValue);
		var len = collection.length,
			entity;

		for (var i = 0; i < len; i++) {
			entity = collection[i];

			if (entity[propertyName] && entity[propertyName] == propertyValue) {
				return entity;
			} else if (entity.getLength) {
				//Entity collections have this property and we want to look inside these collections
				var layer = getEntityByProperty(entity, propertyName, propertyValue);

				if (layer != null) {
					return layer;
				}
			}
		}

		return null;
	};




	return {
		convertMilesToKilometers: convertMilesToKilometers,
		drawCircleLineFromLocation: drawCircleLineFromLocation,
		drawCircleFromLocation: drawCircleFromLocation,
		getElementDimensions: getElementDimensions,
		getEntityByProperty: getEntityByProperty,
		getLayerByName: getLayerByName,
		getUrlParams: getUrlParams,
		viewportDimensions: viewportDimensions,
		upperCaseWord: upperCaseWord
	}

}();