document.addEventListener('DOMContentLoaded', function () {
    // Bootstrap matching Media Queries
    //To use:
    //      Pass mediaQueries into module function: extranet.Utilities.function(mediaQueries)
    //      In module function:
    //          if(mediaQueries.sm.matches){
    //              code here
    //          }
    var mediaQueries = {
        sm: window.matchMedia('(min-width: 576px)'),
        md: window.matchMedia('(min-width: 768px)'),
        lg: window.matchMedia('(min-width: 992px)'),
        xl: window.matchMedia('(min-width: 1200px)')
    }

    //------------------------------------------------------------------------//
    //  Initialize Lazyloading with support for native lazyload
    //
    //  Defaults to use lazyload plugin, but will fall back to native support 
    //  for browsers with support for the loading="lazy" attribute
    //  (https://caniuse.com/#feat=loading-lazy-attr)
    //------------------------------------------------------------------------//
    var lazyLoadInstance = new LazyLoad({
        elements_selector: ".lazy",
        use_native: true 
    });

    
    //------------------------------------------------------------------------//
    //  PUBLIC MODULE FUNCTIONS
    //------------------------------------------------------------------------//
    //  Usage Examples: 
    //      site.Utilities.functionName()
    //      site.Utilities.functionName(mediaQueries)
    //------------------------------------------------------------------------//
    site.Utilities.initSearchDestinations();
    site.Utilities.toggleNavSearch();
    site.Utilities.toggleOffCanvasNav();
    site.Utilities.checkFooter();
    site.Utilities.eventTrackingForGTM($);
    site.Slider.initSwiper();
    
    
    // Check DOM for images loaded via dynamically and update Lazyload instance
    if (lazyLoadInstance) {
        lazyLoadInstance.update();
    }
    
    
    //------------------------------------------------------------------------//
    // WINDOW RESIZE FUNCTIONS
    //------------------------------------------------------------------------//
    window.addEventListener('resize', debounce(function() {
        //AOS.refresh();
        //------------------------------------------------------------------------//
        //  Public module functions
        //------------------------------------------------------------------------//
        //  Usage Examples: 
        //      site.Utilities.functionName()
        //      site.Utilities.functionName(mediaQueries)
        //------------------------------------------------------------------------//
        site.Utilities.checkFooter();
        site.Utilities.closeNavMenus();
    }, 200));

    window.addEventListener('scroll', debounce(function() {
        //console.log('scroll', window.scrollY);
        site.Utilities.checkFooter();
        site.Utilities.closeNavMenus();
    }, 200));

}, false);

//AOS.init();