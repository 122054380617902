var site = site || {};
site.Utilities = (function () {
	// Functions

	// var functionName = function() {
	// };

	var initSearchDestinations = function () {
		//console.log('initSearchDestinations()');

		const urlParams = new URLSearchParams(window.location.search);
		
		const searchDestItems = document.querySelectorAll('.js-search-destinations');
		searchDestItems.forEach(searchDest => {
			console.log('searchDest: ', searchDest);
			console.log('urlParams: ', urlParams);

			if(urlParams.has('Region')) {
				const region = urlParams.get('Region');
				//console.log('urlParams.region: ', region);
				document.getElementById("filterRegion").value = region;
			}
			if(urlParams.has('CapacityVal')) {
				const capacity = urlParams.get('CapacityVal');
				//console.log('urlParams.capacity: ', capacity);
				document.getElementById("filterCapacity").value = capacity;
			}
			if(urlParams.has('GuestRoomsVal')) {
				const rooms = urlParams.get('GuestRoomsVal');
				//console.log('urlParams.rooms: ', rooms);
				document.getElementById("filterGuestRooms").value = rooms;
			}
			if (urlParams.has('VenueTypesVal')) {
				const venues = urlParams.get('VenueTypesVal');
				//console.log('urlParams.venues: ', venues);
				//var test = '2,4,7';
				var venueArr = venues != null ? venues.split(',') : [];
				//console.log('urlParams.venueArr: ', venueArr);
				$('.js-select2-venue').val(venueArr);
				//document.getElementById("filterVenueTypes").value = venues;
			}

			searchDest.addEventListener('click', function(e) {
				//console.log('searchDest.click');

				var venueTypesValueArr = [],
					venueTypesNameArr = [];
				$('.js-select2-venue').find(':selected').each(function() {
					var curValue = $(this).val(),
						curText = $(this).text(),
						curTextTrim = curText != null && curText != undefined ? curText.trim() : '';
					//console.log('curValue:', curValue);
					//console.log('curText:', curText);
					//console.log('curTextTrim:', curTextTrim);
					venueTypesValueArr.push(curValue);
					venueTypesNameArr.push(curTextTrim);
				});
				var venueTypesValue = venueTypesValueArr.join(','),
					venueTypesName = venueTypesNameArr.join(',');
				console.log('venueTypesValue:', venueTypesValue);
				console.log('venueTypesName:', venueTypesName);


				const region = document.getElementById("filterRegion"),
					regionValue = region.value,
					guestRooms = document.getElementById("filterGuestRooms"),
					guestRoomsValue = guestRooms ? guestRooms.value : null,
					guestRoomsName = guestRooms ? guestRooms.options[guestRooms.selectedIndex].text : '',
					capacity = document.getElementById("filterCapacity"),
					capacityValue = capacity ? capacity.value : null,
					capacityName = capacity ? capacity.options[capacity.selectedIndex].text : '';//,
					//venueTypes = document.getElementById("filterVenueTypes"),
					//venueTypesValue = venueTypes ? venueTypes.value : null;//,
					//venueTypesName = venueTypes ? venueTypes.options[venueTypes.selectedIndex].text : '';
					

				var baseUrl = '/destinations',
					newParams = new URLSearchParams();

				if(regionValue) newParams.append('Region', String(regionValue));
				if(guestRoomsValue){
					newParams.append('GuestRoomsVal', String(guestRoomsValue));
					newParams.append('GuestRoomsName', String(guestRoomsName));
				}
				if(capacityValue){
					newParams.append('CapacityVal', String(capacityValue));
					newParams.append('CapacityName', String(capacityName));
				}
				if (venueTypesValue) {
					newParams.append('VenueTypesVal', String(venueTypesValue));
					newParams.append('VenueTypesName', String(venueTypesName));
				}


				//const searchUrl = baseUrl + '?' + newParams.toString() + '&#placeDirectory';
				const searchUrl = baseUrl + '?' + newParams.toString() + '&#mapDestinations';
				console.log('newParams:', newParams.toString());
				console.log('searchUrl:', searchUrl);
				window.location.href = searchUrl;
			});
		});


		console.log('utilities js-select2-venue');
		//var results = [];
		$('.js-select2-venue').select2({
			placeholder: "Select Venue Type",
			allowClear: true//,
			// query: function (query){
			// 	var data = {results: []};
			// 	$.each(yuyu, function(){
			// 		if(query.term.length == 0 || this.text.toUpperCase().indexOf(query.term.toUpperCase()) >= 0 ){
			// 			data.results.push({id: this.id, text: this.text });
			// 		}
			// 	});
		
			// 	results = data.results;
			// 	query.callback(data);
			// 	console.log('results:', results);
			// 	console.log('data:', data);
			// }
		});
	}


	/**
	 * Toggle Nav Search visibility
	*/
	var toggleNavSearch = function () {
		//console.log('toggleNavSearch()');
		const toggleSearchItems = document.querySelectorAll('.js-toggle-search');
		const offcanvasSearch = document.getElementById('js-offcanvas-search');
		const closeSearch = document.getElementById('js-close-search');
		//console.log('toggleSearchItems', toggleSearchItems);

		toggleSearchItems.forEach(searchItem => {
			//console.log('searchItem added');
			searchItem.addEventListener('click', function(e) {
				//console.log('searchItem.click');
				searchItem.classList.toggle('animate');
				offcanvasSearch.classList.toggle('active');
			});
		});

		closeSearch.addEventListener('click', function(e) {
			//console.log('closeSearch.click');
			toggleSearchItems.forEach(searchItem => {
				searchItem.classList.remove('animate');
			});
			offcanvasSearch.classList.remove('active');
		});

		document.addEventListener('click', function(e){
			//console.log('doc.click', e);
			if (!e.target.matches('.js-toggle-search, .js-toggle-search *, #js-offcanvas-search, #js-offcanvas-search *')) {
				//console.log('NON-search.click');
				toggleSearchItems.forEach(searchItem => {
					searchItem.classList.remove('animate');
				});
				offcanvasSearch.classList.remove('active');
			}
		})
	}


	/**
	 * Toggle Off Canvas (Mobile) Nav Search visibility
	*/
	var toggleOffCanvasNav = function () {
		const toggleNav = document.getElementById('js-toggle-nav');
		const offcanvasNav = document.getElementById('js-offcanvas-nav');
		const offcanvasNavShadow = document.createElement("div");
		document.body.appendChild(offcanvasNavShadow);
		offcanvasNavShadow.classList.add('offcanvas-nav-shadow');
		offcanvasNavShadow.setAttribute("id", "js-offcanvas-nav-shadow")

		if(offcanvasNav != null) {
			toggleNav.addEventListener('click', function(e) {
				toggleNav.classList.toggle('animate');
				offcanvasNav.classList.toggle('active');
				offcanvasNavShadow.classList.toggle('active');
			});
	
			document.addEventListener('click', function(e){
				if (!e.target.matches('#js-toggle-nav, #js-toggle-nav *, #js-offcanvas-nav, #js-offcanvas-nav *, #js-offcanvas-search, #js-offcanvas-search *')) {
					toggleNav.classList.remove('animate');
					offcanvasNav.classList.remove('active');
					offcanvasNavShadow.classList.remove('active');
				}
			})
		}
	}


	/**
	 * Close nav menus
	*/
	var closeNavMenus = function() {
		//console.log('closeNavMenus');
		const toggleNav = document.getElementById('js-toggle-nav');
		const offcanvasNav = document.getElementById('js-offcanvas-nav');
		const offcanvasNavShadow = document.getElementById('js-offcanvas-nav-shadow');
		//const toggleSearchItems = document.querySelectorAll('.js-toggle-search');
		const offcanvasSearch = document.getElementById('js-offcanvas-search');

		toggleNav.classList.remove('animate');
		offcanvasNav.classList.remove('active');
		offcanvasSearch.classList.remove('active');
		offcanvasNavShadow.classList.remove('active');

		//closeAllNavSubmenus();
	}


	// var closeAllNavSubmenus = function () {
	// 	console.log('closeAllNavSubmenus');

	// 	const subMenuBodies = document.querySelectorAll('.js-nav-submenu-body');
	// 	subMenuBodies.forEach(body => {
	// 		console.log('subMenuBodies.body:', body);
	// 		body.classList.add('hidden');
	// 	});
	// }

	


	/**
	 * Check Footer - if window height is taller than body, move footer to absolute bottom
	*/
	var checkFooter = function () {
		//console.group('checkFooter');
		var winHeight = window.innerHeight,
			header = document.getElementsByTagName('header'),
			main = document.getElementById('main'),
			footer = document.getElementById('footer'),
			headerHeight = header ? header[0].offsetHeight : 0,
			mainHeight = main ? main.offsetHeight : 0,
			footerHeight = footer ? footer.offsetHeight : 0,
			bodyHeight = headerHeight + mainHeight + footerHeight;
			//bodyHeight = document.body.offsetHeight;//footer doesn't get added with body height when it's position:absolute

		// console.log('headerHeight:', headerHeight);
		// console.log('mainHeight:', mainHeight);
		// console.log('footerHeight:', footerHeight);
		// console.log('bodyHeight:', bodyHeight);
		// console.log('winHeight:', winHeight);
		// console.groupEnd();

		if(header && main && footer && winHeight > bodyHeight) {
			footer.classList.add('absolute-bottom');
		} else {
			footer.classList.remove('absolute-bottom');
		}

	}


	var eventTrackingForGTM = function($) {
        $(document).on('click', '.js-nav-link', function(){
            dataLayer.push({'event': 'sidebarNav'});
        })
    }



	// Return
	return {
		checkFooter: checkFooter,
		closeNavMenus: closeNavMenus,
		eventTrackingForGTM: eventTrackingForGTM,
		initSearchDestinations: initSearchDestinations,
		toggleNavSearch : toggleNavSearch,
		toggleOffCanvasNav : toggleOffCanvasNav
	};
}());