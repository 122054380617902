var wdot = wdot || {};
wdot.ContentPages = function () {
    var fancyDirectory = {
        over: function () {
            var position = $(this).position();
            var vertposition = position.top + 6;
            $('span.leftArrow').css("top", vertposition);
            $('span.rightArrow').css("top", vertposition);
            $(this).siblings().removeClass('active hover');
            $(this).addClass('active hover');
            wdot.ContentPages.DirectoryListing.MovePin($(this).attr("data-lat"), $(this).attr("data-long"));
        }, // function = onMouseOver callback (REQUIRED)
        timeout: 600, // number = milliseconds delay before onMouseOut
        out: function () {
            $(this).removeClass('hover');
        } // function = onMouseOut callback (REQUIRED)
    };
    var directoryListing = function () {

        var map,
        cityMap = null,
        roadTripMap = null,
        cityPageListingTypes = { RoadTrip: "roadtrips", Cities: "cities" },
        loadCityPageMap = function (type, pinLat, pinLong, multiPinPointsJSONString) {
            var selector = type == cityPageListingTypes.Cities ? $(".regionDirectory .citiesList .directoryMap") : $(".regionDirectory .roadTripsList .directoryMap");
            var currentMap = type == cityPageListingTypes.RoadTrip ? roadTripMap : cityMap;
            if (currentMap == null) {
                var latitude = 44.832017349893526,
                longitude = -89.84687250000002;
                var center = new Microsoft.Maps.Location(latitude, longitude);
                currentMap = new Microsoft.Maps.Map(selector[0], { enableSearchLogo: false, showMapTypeSelector: false, credentials: wdot.BingMaps.apiKey, zoom: 11, center: center });

                if (type == cityPageListingTypes.RoadTrip)
                    roadTripMap = currentMap;
                else
                    cityMap = currentMap;

                selector.bind('contextmenu', function (event) {
                    return false;
                });
            }

            currentMap.entities.clear();
            var pinOptions = { visible: true, draggable: false };
            pinOptions = wdot.BingMaps.PinImageWithOutText(pinOptions);
            if (type == "roadtrips" && multiPinPointsJSONString != undefined && multiPinPointsJSONString != "") {
                var points = JSON.parse(multiPinPointsJSONString);
                $(points).each(function () {
                    var pin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(this.Latitude, this.Longitude), pinOptions);
                    currentMap.entities.push(pin);
                });
            }
            else {
                var pin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(pinLat, pinLong), pinOptions);
                currentMap.entities.push(pin);
            }

            bestViewCitiesPage(type, currentMap);

            $.debounce(250, selector.removeClass("hidden"));
        },
        loadMap = function (pinLat, pinLong) {
            var directoryMap = $($(".directoryMap")[0]);

            if (map != null && directoryMap.children().length > 0) {
                movePin(pinLat, pinLong);
                return;
            }

            var latitude = 44.832017349893526,
                longitude = -89.84687250000002;

            var center = new Microsoft.Maps.Location(latitude, longitude);
            map = new Microsoft.Maps.Map($(".directoryMap")[0], { enableSearchLogo: false, showMapTypeSelector: false, credentials: wdot.BingMaps.apiKey, zoom: 2, center: center });
            var pinOptions = { visible: true, draggable: false };
            pinOptions = wdot.BingMaps.PinImageWithOutText(pinOptions);
            var pin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(pinLat, pinLong), pinOptions);
            map.entities.push(pin);
            bestView();
            $(".directoryMap").bind('contextmenu', function (event) {
                return false;
            });

            $.debounce(250, $(".directoryMap").removeClass("hidden"));
        },
        movePin = function (lat, lon) {
            if (map != null) {
                map.entities.get(0).setLocation(new Microsoft.Maps.Location(lat, lon));
                bestView();
            }
        },
        bestViewCitiesPage = function (type, map) {
            var selector = type == cityPageListingTypes.Cities ? $(".regionDirectory .citiesList .directoryMap") : $(".regionDirectory .roadTripsList .directoryMap");

            var center = new Microsoft.Maps.Location(44.832017349893526, -89.84687250000002),
            zoomLevel = 0,
            maxLat = -85,
            minLat = 85,
            maxLon = -180,
            minLon = 180,
            length = map.entities.getLength(),
            buffer = 5,
            mapWidth = selector.width(),
            mapHeight = selector.height();

            for (var i = 0; i < length; i++) {
                var location = map.entities.get(i)._location;
                if (location.latitude > maxLat)
                    maxLat = location.latitude;

                if (location.latitude < minLat)
                    minLat = location.latitude;

                if (location.longitude > maxLon)
                    maxLon = location.longitude;

                if (location.longitude < minLon)
                    minLon = location.longitude;
            }

            var zoom1 = 15, zoom2 = 15;
            if (length > 0) {
                center.latitude = (maxLat + minLat) / 2;
                center.longitude = (maxLon + minLon) / 2;

                if (maxLon != minLon && maxLat != minLat) {
                    zoom1 = Math.log(360.0 / 256.0 * (mapWidth - 2 * buffer) / (maxLon - minLon)) / Math.log(2);
                    zoom2 = Math.log(180.0 / 256.0 * (mapHeight - 2 * buffer) / (maxLat - minLat)) / Math.log(2);
                }
            } else {
                zoom1 = 7;
                zoom2 = 7;
            }

            zoomLevel = (zoom1 < zoom2) ? zoom1 : zoom2;

            map.setView({ center: center, zoom: zoomLevel });
        },
        bestView = function () {
            var center = new Microsoft.Maps.Location(44.832017349893526, -89.84687250000002),
            zoomLevel = 0,
            maxLat = -85,
            minLat = 85,
            maxLon = -180,
            minLon = 180,
            length = map.entities.getLength(),
            buffer = 5,
            mapWidth = $(".directoryMap").width(),
            mapHeight = $(".directoryMap").height();

            for (var i = 0; i < length; i++) {
                var location = map.entities.get(i)._location;
                if (location.latitude > maxLat)
                    maxLat = location.latitude;

                if (location.latitude < minLat)
                    minLat = location.latitude;

                if (location.longitude > maxLon)
                    maxLon = location.longitude;

                if (location.longitude < minLon)
                    minLon = location.longitude;
            }

            var zoom1 = 15, zoom2 = 15;
            if (length > 0) {
                center.latitude = (maxLat + minLat) / 2;
                center.longitude = (maxLon + minLon) / 2;

                if (maxLon != minLon && maxLat != minLat) {
                    zoom1 = Math.log(360.0 / 256.0 * (mapWidth - 2 * buffer) / (maxLon - minLon)) / Math.log(2);
                    zoom2 = Math.log(180.0 / 256.0 * (mapHeight - 2 * buffer) / (maxLat - minLat)) / Math.log(2);
                }
            } else {
                zoom1 = 7;
                zoom2 = 7;
            }

            zoomLevel = (zoom1 < zoom2) ? zoom1 : zoom2;

            // override so it's always at the state level
            zoomLevel = 6;

            map.setView({ center: center, zoom: zoomLevel });
        },
        loadIndustryTravelGreenPage = function (currentDirectory, resetPaging, currentPageNum, getDefaultValues, qs, pushHistory) {
            wdot.Utils.ResetPlaceHolders();
            var pagingContainer = currentDirectory.find(".pagingContainer");
            var currentPage = currentPageNum || 1;
            var widgetId = currentDirectory.attr("data-unique-widget-id");
            var contentPageId = currentDirectory.attr("data-contentId");
            var baseContentId = currentDirectory.attr("data-basecontentid");
            pagingContainer.attr('data-currentpage', !resetPaging ? currentPageNum : 1);
            var pagingAjaxData = {};
            if (qs == null || qs == undefined || qs == {}) {
                if (!getDefaultValues) {
                    pagingAjaxData.ContentPageId = contentPageId;
                    pagingAjaxData.CurrentPage = !resetPaging ? currentPage : 1;
                    pagingAjaxData.ContentPage_WidgetId = widgetId;
                    pagingAjaxData.BaseContentId = baseContentId;
                    var filters = currentDirectory.find(".filters");
                    if (filters.length != 0) {
                        pagingAjaxData.SortBy = filters.find("select[name=SortBy]").val();
                        pagingAjaxData.CategoryId = filters.find("select[name=CategoryId]").val();
                    }
                }
                else {
                    if ($("[name=DirectoryDefaultValue_" + widgetId + "]").length > 0 && $("[name=DirectoryDefaultValue_" + widgetId + "]").val() != "") {
                        pagingAjaxData = JSON.parse($("[name=DirectoryDefaultValue_" + widgetId + "]").val());
                    }
                    pagingAjaxData.ContentPage_WidgetId = widgetId;
                    pagingAjaxData.ContentPageId = contentPageId;
                    pagingAjaxData.BaseContentId = baseContentId;
                }
            }

            if (pushHistory) {
                wdot.AjaxStateHandler.UpdateHistory('/Directory/' + pagingAjaxData.CurrentPage + '?' + $.param(pagingAjaxData));
            }

            wdot.Ajax.Post("/ContentPage/IndustryTravelGreenListing?IsUpdate=true&" + (qs ? qs : ''), JSON.stringify(pagingAjaxData), function (d) {
                var uniqueWidgetId = $(d).map(function () { return $(this).attr("data-unique-widget-id"); }).get()[0];
                $('[data-unique-widget-id=' + uniqueWidgetId + ']').replaceWith(d);
                var count = $('[data-unique-widget-id=' + uniqueWidgetId + ']').attr("data-item-count");
                if (count != undefined) {
                    $(".directoryList [href=#directory]").find(".count").html(" (" + count + ")");
                }
                $('ul.listings > li').hoverIntent(fancyDirectory);
                placeHolderEvents($("#directory .directoryListContainer"));
            }, null, 'application/json');

        },
        loadPage = function (currentDirectory, resetPaging, currentPageNum, getDefaultValues, qs, pushHistory) {
            // console.log('ContentPages.js loadPage.currentDirectory:', currentDirectory);
            // console.log('ContentPages.js loadPage.resetPaging:', resetPaging);
            // console.log('ContentPages.js loadPage.currentPageNum:', currentPageNum);
            // console.log('ContentPages.js loadPage.getDefaultValues:', getDefaultValues);
            // console.log('ContentPages.js loadPage.qs:', qs);
            // console.log('ContentPages.js loadPage.pushHistory:', pushHistory);
            //debugger;
            wdot.Utils.ResetPlaceHolders();
            var isArticle = currentDirectory.hasClass("articleDirectoryListing");
            var pagingContainer = currentDirectory.find(".pagingContainer");
            var currentPage = currentPageNum || 1;
            var widgetId = currentDirectory.attr("data-unique-widget-id");
            var contentPageId = currentDirectory.attr("data-contentId");
            var baseContentId = currentDirectory.attr("data-basecontentid");
            pagingContainer.attr('data-currentpage', !resetPaging ? currentPageNum : 1);

            // console.log('ContentPages.js loadPage.contentPageId:', contentPageId);
            // console.log('ContentPages.js loadPage.baseContentId:', baseContentId);

            var pagingAjaxData = {};
            if (qs == null || qs == undefined || qs == {}) {
                if (!getDefaultValues && !isArticle) {
                    pagingAjaxData.ContentPageId = contentPageId;
                    pagingAjaxData.CurrentPage = !resetPaging ? currentPage : 1;
                    pagingAjaxData.ContentPage_WidgetId = widgetId;
                    pagingAjaxData.BaseContentId = baseContentId;
                    var filters = currentDirectory.find(".filters");

                    if (filters.length != 0) {
                        pagingAjaxData.CityOrZip = filters.find("input[name=CityOrZip]").val();
                        if (pagingAjaxData.CityOrZip == undefined) pagingAjaxData.CityOrZip = '';

                        pagingAjaxData.County = filters.find("input[name=County]").val();
                        if (pagingAjaxData.County == undefined) pagingAjaxData.County = '';

                        pagingAjaxData.MileRange = filters.find("select[name=MileRange]").val();
                        if (pagingAjaxData.MileRange == undefined) pagingAjaxData.MileRange = '';

                        pagingAjaxData.Region = filters.find("select[name=Region]").val();
                        if (pagingAjaxData.Region == undefined) pagingAjaxData.Region = '';

                        pagingAjaxData.Keyword = filters.find("input[name=Keyword]").val();
                        if (pagingAjaxData.Keyword == undefined) pagingAjaxData.Keyword = '';

                        pagingAjaxData.SquareFootage = filters.find("select[name=SquareFootage]").val();
                        if (pagingAjaxData.SquareFootage == undefined) pagingAjaxData.SquareFootage = '';

                        pagingAjaxData.LodgingRooms = filters.find("select[name=LodgingRooms]").val();
                        if (pagingAjaxData.LodgingRooms == undefined) pagingAjaxData.LodgingRooms = '';

                        pagingAjaxData.FlatFloorSpace = filters.find("select[name=FlatFloorSpace]").val();
                        if (pagingAjaxData.FlatFloorSpace == undefined) pagingAjaxData.FlatFloorSpace = '';

                    }
                    var i = 0;
                    pagingAjaxData.AttributeFilters = [];
                    ko.utils.arrayForEach($(".directoryFilters input[type=textbox], .directoryFilters input[type=checkbox]:checked"), function (item) {
                        pagingAjaxData.AttributeFilters[i.toString()] = { AttributeId: $(item).attr("data-attribute-id"), Value: $(item).val(), Name: $(item).attr("data-attribute-name") };
                        i++;
                    });

                    // handle the dropdowns a bit different
                    ko.utils.arrayForEach($(".directoryFilters select"), function (item) {
                        if ($(item).val() != null && $(item).val() != '' && $(item).attr('data-skip') != 'true') {
                            var obj = { AttributeId: $(item).attr("data-attribute-id"), Value: $(item).val(), Name: $(item).find(':selected').attr("data-attribute-name") };
                            pagingAjaxData.AttributeFilters[i.toString()] = obj;
                            i++;
                        }
                    });

                } else if (isArticle) {
                    pagingAjaxData.ContentPageId = contentPageId;
                    pagingAjaxData.CurrentPage = !resetPaging ? currentPage : 1;
                    pagingAjaxData.ContentPage_WidgetId = widgetId;
                    pagingAjaxData.BaseContentId = baseContentId;
                }
                else {
                    if ($("[name=DirectoryDefaultValue_" + widgetId + "]").length > 0 && $("[name=DirectoryDefaultValue_" + widgetId + "]").val() != "") {
                        pagingAjaxData = JSON.parse($("[name=DirectoryDefaultValue_" + widgetId + "]").val());
                    }
                    pagingAjaxData.ContentPage_WidgetId = widgetId;
                    pagingAjaxData.ContentPageId = contentPageId;
                    pagingAjaxData.BaseContentId = baseContentId;
                }
            }

            if (pushHistory) {
                wdot.AjaxStateHandler.UpdateHistory((isArticle ? '/Article/' : '/Directory/') + pagingAjaxData.CurrentPage + '?' + $.param(pagingAjaxData));
            }

            //console.log('ContentPages.js pagingAjaxData:', pagingAjaxData);

            wdot.Ajax.Post("/ContentPage/GetDirectoryListingWidget?IsUpdate=true&" + qs, JSON.stringify(pagingAjaxData), function (d) {

               // console.log('ContentPages.js wdog.ajax.post.done.pagingAjaxData:', pagingAjaxData);
                //console.log('ContentPages.js wdog.ajax.post.done.d:', d);

                var uniqueWidgetId = $(d).map(function () {
                    return $(this).attr("data-unique-widget-id");
                }).get()[0];

                $('[data-unique-widget-id=' + uniqueWidgetId + ']').replaceWith(d);
                var count = $('[data-unique-widget-id=' + uniqueWidgetId + ']').attr("data-item-count");
                if (count != undefined) {
                    //$(".directoryList [href=#directory]").find(".count").html(" (" + count + ")");
                    $(".directoryList #directory").find(".count").html(" (" + count + ")");
                }
                //console.log('ContentPages.js wdog.ajax.post.done.count:', count);
                $('ul.listings > li').hoverIntent(fancyDirectory);
                placeHolderEvents($(".directoryListContainer"));

            }, null, 'application/json');

        },
        loadCitiesAndRegionsPage = function (directoryContianer, resetPaging, pageNumber, getDefaultValues, qs, pushHistory) {
            wdot.Utils.ResetPlaceHolders();
            var pagingContainer = directoryContianer.find(".pagingContainer");
            var currentPage = pageNumber == null || pageNumber == undefined ? (pagingContainer.find(".carousel-active-tab a").attr('data-pagenumber') || 1) : pageNumber;
            var widgetId = directoryContianer.attr("data-unique-widget-id");
            var contentPageId = directoryContianer.attr("data-contentId");
            var isRoadTrip = directoryContianer.attr("data-isroadtrip");
            pagingContainer.attr('data-currentpage', !resetPaging ? currentPage : 1);
            var pagingAjaxData = {};
            if (qs == null || qs == undefined || qs == "" || qs == {}) {
                if (!getDefaultValues) {
                    pagingAjaxData.ContentPageId = contentPageId;
                    pagingAjaxData.CurrentPage = !resetPaging ? currentPage : 1;
                    pagingAjaxData.ContentPage_WidgetId = widgetId;
                    var filters = directoryContianer.find(".filters");

                    pagingAjaxData.CityOrZip = filters.find("input[name=CityOrZip]").val();
                    pagingAjaxData.County = filters.find("input[name=County]").val();
                    pagingAjaxData.MileRange = filters.find("select[name=MileRange]").val();
                    pagingAjaxData.Region = filters.find("select[name=Region]").val();
                    pagingAjaxData.isRoadTrip = isRoadTrip;

                    var i = 0;
                    pagingAjaxData.AttributeFilters = [];
                    ko.utils.arrayForEach(directoryContianer.find(".directoryFilters input[type=textbox], .directoryFilters input[type=checkbox]:checked"), function (item) {
                        pagingAjaxData.AttributeFilters[i.toString()] = { AttributeId: $(item).attr("data-attribute-id"), Value: $(item).val(), Name: $(item).attr("data-attribute-name") };
                        i++;
                    });
                } else {
                    pagingAjaxData = JSON.parse($("[name=DirectoryDefaultValues]").val());
                    pagingAjaxData.ContentPage_WidgetId = widgetId;
                    pagingAjaxData.ContentPageId = contentPageId;
                }
            }

            if (pushHistory) {
                wdot.AjaxStateHandler.UpdateHistory((isRoadTrip == "true" ? '/roadTrips/' : '/cities/') + pagingAjaxData.CurrentPage + '?' + $.param(pagingAjaxData));
            }

            wdot.Ajax.Post("/ContentPage/GetCitiesDirectoryList?IsUpdate=true&" + qs, JSON.stringify(pagingAjaxData), function (d) {
                directoryContianer.replaceWith(d);
                var count = directoryContianer.attr("data-item-count");
                directoryContianer.find(".directoryList .count").html(" (" + count + ")");
                $('ul.listings > li').hoverIntent(fancyDirectory);
                placeHolderEvents($("#cities .directoryListContainer"));
                placeHolderEvents($("#roadTrips .directoryListContainer"));
            }, null, 'application/json');
        },
        loadTripsPage = function (directoryContianer, resetPaging, data, pushHistory, currentPageNum) {
            wdot.Utils.ResetPlaceHolders();
            var pagingContainer = directoryContianer.find(".pagingContainer");
            var currentPage = currentPageNum || 1;
            var widgetId = directoryContianer.attr("data-unique-widget-id");
            var contentPageId = directoryContianer.attr("data-contentId");
            pagingContainer.attr('data-currentpage', !resetPaging ? currentPage : 1);
            var pagingAjaxData = {};
            pagingAjaxData.ContentPageId = contentPageId;
            pagingAjaxData.CurrentPage = !resetPaging ? currentPage : 1;
            pagingAjaxData.ContentPage_WidgetId = widgetId;
            var filters = directoryContianer.find(".filters");

            pagingAjaxData.CityOrZip = filters.find("input[name=CityOrZip]").val();
            pagingAjaxData.MileRange = filters.find("select[name=MileRange]").val();
            pagingAjaxData.Region = filters.find("select[name=Region]").val();

            if (pushHistory) {
                wdot.AjaxStateHandler.UpdateHistory('/Directory/' + pagingAjaxData.CurrentPage + '?' + $.param(pagingAjaxData));
            }
            wdot.Ajax.Post("/ContentPage/GetTripsDirectoryList?IsUpdate=true", JSON.stringify(pagingAjaxData), function (d) {
                directoryContianer.replaceWith(d);
                var count = directoryContianer.attr("data-item-count");
                directoryContianer.parents("body").find(".directoryList .count").html(" (" + count + ")");
                $('ul.listings > li').hoverIntent(fancyDirectory);
                placeHolderEvents($("#directory .directoryListContainer"));

            }, null, 'application/json');
        },
        loadEventsPage = function (directoryContianer, resetPaging, newPageNumber, getDefaultValues, qs, pushHistory) {
            if (pushHistory == undefined) {
                pushHistory = false;
            }
            var isArticle = directoryContianer.hasClass("articleDirectoryListing");
            wdot.Utils.ResetPlaceHolders();
            var pagingAjaxData = {};
            var categoryQueryString = '';
            if (qs == null || qs == undefined || qs == {}) {
                if (!getDefaultValues && !isArticle) {
                    var pagingContainer = directoryContianer.find(".pagingContainer");
                    var currentPage = newPageNumber || 1;
                    var widgetId = directoryContianer.attr("data-unique-widget-id");
                    var contentPageId = directoryContianer.attr("data-contentId");
                    var baseContentId = directoryContianer.attr("data-basecontentid");
                    pagingContainer.attr('data-currentpage', !resetPaging ? currentPage : 1);
                    pagingAjaxData.ContentPageId = contentPageId;
                    pagingAjaxData.CurrentPage = !resetPaging ? currentPage : 1;
                    pagingAjaxData.ContentPage_WidgetId = widgetId;
                    pagingAjaxData.BaseContentId = baseContentId;
                    var filters = directoryContianer.find(".filters");

                    pagingAjaxData.Keyword = filters.find("input[name=Keyword]").val();
                    pagingAjaxData.StartDate = filters.find("input[name=StartDate]").val();
                    pagingAjaxData.EndDate = filters.find("input[name=EndDate]").val();
                    pagingAjaxData.CityOrZip = filters.find("input[name=CityOrZip]").val();
                    pagingAjaxData.MileRange = filters.find("select[name=MileRange]").val();

                    var i = 0;
                    pagingAjaxData.AttributeFilters = [];
                    ko.utils.arrayForEach(directoryContianer.find(".directoryFilters input[type=textbox], .directoryFilters input[type=checkbox]:checked"), function (item) {
                        pagingAjaxData.AttributeFilters[i.toString()] = { AttributeId: $(item).attr("data-attribute-id"), Value: $(item).val(), Name: $(item).attr("data-attribute-name") };
                        i++;
                    });
                } else {
                    var widgetId = directoryContianer.attr("data-unique-widget-id");
                    var contentPageId = directoryContianer.attr("data-contentId");
                    var baseContentId = currentDirectory.attr("data-basecontentid");
                    pagingAjaxData = JSON.parse($("[name=DirectoryDefaultValue_" + widgetId + "]").val());
                    pagingAjaxData.ContentPage_WidgetId = widgetId;
                    pagingAjaxData.ContentPageId = contentPageId;
                    pagingAjaxData.BaseContentId = baseContentId;
                }

                var queryString = window.location.search.replace("?", "").split("&");
                for (var t = 0; t < queryString.length; t++) {
                    if (queryString[t].split("=")[0] == "categoryId" || queryString[t].split("=")[0] == "category") {
                        categoryQueryString += "&" + queryString[t];
                    }
                }
            } else {
                categoryQueryString = qs;
            }

            if (pushHistory) {
                wdot.AjaxStateHandler.UpdateHistory(isArticle ? '/Articles/' : '/Directory/' + pagingAjaxData.CurrentPage + '?' + categoryQueryString + $.param(pagingAjaxData));
            }

            wdot.Ajax.Post("/ContentPage/GetDirectoryListingWidget?IsUpdate=true" + categoryQueryString, JSON.stringify(pagingAjaxData), function (d) {
                $(".datesDisplay").remove();
                directoryContianer.replaceWith(d);
                var count = $("[data-item-count]").attr("data-item-count");
                $(".directoryList .count").html(" (" + count + ")");
                $(".filterdatepicker").datepicker({
                    onSelect: function () {
                        var sd = $(".directoryListContainer .filters input[name=StartDate]");
                        var ed = $(".directoryListContainer .filters input[name=EndDate]");
                        var prevStartDate = sd.attr("data-prevdate");
                        var prevEndDate = ed.attr("data-prevdate");
                        var startDate = new Date(sd.val());
                        var endDate = new Date(ed.val());
                        if ($(this).attr("name") == "StartDate") {
                            if (startDate > endDate) {
                                sd.val(prevStartDate);
                                alert('Start Date must be less than End Date');
                            } else {
                                sd.attr("data-prevdate", $(this).val());
                                wdot.ContentPages.DirectoryListing.DebounceFilterInput($(this).parents(".directoryListContainer"), true, wdot.ContentPages.DirectoryListing.LoadEventsPage);
                            }
                        } else {
                            if (endDate < startDate) {
                                ed.val(prevEndDate);
                                alert('Start Date must be less than End Date');
                            } else {
                                ed.attr("data-prevdate", $(this).val());
                                wdot.ContentPages.DirectoryListing.DebounceFilterInput($(this).parents(".directoryListContainer"), true, wdot.ContentPages.DirectoryListing.LoadEventsPage);
                            }
                        }
                    }
                });
                $('ul.listings > li').hoverIntent(fancyDirectory);
                placeHolderEvents($("#directory .directoryListContainer"));
            }, null, 'application/json');
        },
        debounceTimeout = null,
        debounceFilterInput = function (currentPageButton, resetPaging, fn) {
            window.clearInterval(debounceTimeout);
            debounceTimeout = setInterval(function () { fn(currentPageButton, resetPaging); window.clearInterval(debounceTimeout); }, 200);
        },
        loadMobileEventMap = function () {
            var latLongs = $(".mobileDirectoryList ul li[data-lat]").map(function () {
                return { lat: $(this).attr("data-lat"), lon: $(this).attr("data-long") };
            });

            var latitude = 44.832017349893526,
                longitude = -89.84687250000002;

            var center = new Microsoft.Maps.Location(latitude, longitude);
            if (map == null) {
                map = new Microsoft.Maps.Map($(".directoryMap")[0], { enableSearchLogo: false, showMapTypeSelector: false, credentials: wdot.BingMaps.apiKey, zoom: 11, center: center });
            } else {
                map.entities.clear();
            }
            var title = 1;
            ko.utils.arrayForEach(latLongs, function (item) {
                if (item.lat != 0 && item.lon != 0) {
                    var pinOptions = { visible: true, draggable: false, text: '' + title };
                    var pin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(item.lat, item.lon), pinOptions);
                    map.entities.push(pin);
                }
                title++;
            });

            $(".directoryMap").bind('contextmenu', function (event) {
                return false;
            });

            $.debounce(250, $(".directoryMap").removeClass("hidden"));
            bestView();
        },
        clearEventFilters = function () {
            $("input[name=Keyword],input[name=StartDate],input[name=EndDate],input[name=CityOrZip]").val('');
            $("select[name=MileRange]").val(0);
            $('[data-attribute-name]').attr("checked", false);
            $('[data-attribute-name]').val('');
            $('[data-attribute-name]').val(0);
            $(".directoryList .update").click();
        },
        clearMobileFilters = function () {
            $("input[name=County],input[name=CityOrZip]").val('');
            $("select[name=MileRange]").val(0);
            $("select[name=Region]").val(0);
            $("select[name=SquareFootage]").val("");
            $("select[name=LodgingRooms]").val("");
            $('[data-attribute-name]').attr("checked", false);
            $('[data-attribute-name]').val('');
            $('[data-attribute-name]').val(0);
        },
        clearFilters = function () {
            $("input[name=County],input[name=CityOrZip],input[name=Keyword]").val('');
            $("select[name=MileRange]").val(0);
            $("select[name=Region]").val(0);
            $("select[name=SquareFootage]").val("");
            $("select[name=LodgingRooms]").val("");
            $('[data-attribute-name]').attr("checked", false);
            $('[data-attribute-name]').attr("selected", false);
            $('[data-attribute-name]').val('');
            $('[data-attribute-name]').val(0);
            $(".directoryListContainer .update").click();
        },
        clearIndustryTravelGreenFilters = function () {
            $("select[name=SortBy],select[name=CategoryId]").val(0);
            $(".directoryListContainer .update").click();
        },
        clearCitiesAndRegionsFilters = function () {
            var isCityTab = $(".ui-tabs-active").children("a").attr("href").indexOf("cities") >= 0;
            var directory = isCityTab ? $("#cities") : $("#roadTrips");
            directory.find("input[name=County],input[name=CityOrZip]").val('');
            directory.find("select[name=MileRange]").val(0);
            directory.find("select[name=Region]").val(0);
            directory.find(".update").click();
        },
        clearTripFilters = function () {
            $("input[name=CityOrZip]").val('');
            $("select[name=MileRange]").val(0);
            $("select[name=Region]").val(0);
            $(".directoryListContainer .update").click();
        },
        placeHolderEvents = function (directoryContainer) {
            //console.log('ContentPages.js.placeHolderEvents: ' + directoryContainer);
            wdot.CityRegionsHelper.initRegionMapButtons();
            directoryContainer.find('[placeholder]').focus(function () {
                var input = $(this);
                if (input.val() == input.attr('placeholder')) {
                    input.val('');
                    input.removeClass('placeholder');
                }
            }).blur(function () {
                var input = $(this);
                if (input.val() == '' || input.val() == input.attr('placeholder')) {
                    input.addClass('placeholder');
                    input.val(input.attr('placeholder'));
                }
            }).blur();

            directoryContainer.find('.map-list__link').click(function () {
                //console.log('directoryContainer.map-list__link.click');
                var region = $(this);
                $('div.filters #Region').val(region.attr('data-value'));
                $('.filters .map-list__link').removeClass('active');
                region.addClass('active');
                return false;
            });

            directoryContainer.find('.update').click(function () {
                $(this).find('[placeholder]').each(function () {
                    var input = $(this);
                    if (input.val() == input.attr('placeholder')) {
                        input.val('');
                    }
                });

                //wdot.ContentPages.DirectoryListing.LoadPage($(".directoryListContainer"), true, 1, null, null, true);
            });

        };
        return {
            LoadMap: loadMap,
            MovePin: movePin,
            BestView: bestView,
            LoadPage: loadPage,
            LoadMobileEventMap: loadMobileEventMap,
            LoadCitiesAndRegionsPage: loadCitiesAndRegionsPage,
            LoadEventsPage: loadEventsPage,
            DebounceFilterInput: debounceFilterInput,
            ClearEventFilters: clearEventFilters,
            ClearMobileFilters: clearMobileFilters,
            ClearFilters: clearFilters,
            ClearCitiesAndRegionsFilters: clearCitiesAndRegionsFilters,
            ClearTripFilters: clearTripFilters,
            LoadTripsPage: loadTripsPage,
            LoadIndustryTravelGreenPage: loadIndustryTravelGreenPage,
            PlaceHolderEvents: placeHolderEvents,
            LoadCityPageMap: loadCityPageMap,
            CityPageListingTypes: cityPageListingTypes,
            ClearIndustryTravelGreenFilters: clearIndustryTravelGreenFilters
        };
    } ();
    return {
        DirectoryListing: directoryListing
    };
} ();